import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json, Link, useLoaderData } from '@remix-run/react'
import { Button } from '~/components/ui/button'
import { TContext } from '~/lib/types'

export const meta: MetaFunction = () => {
    return [
        { title: 'Examo' },
        { name: 'description', content: 'Welcome to Examo!' },
    ]
}

export async function loader(args: LoaderFunctionArgs) {
    const context = args.context as TContext
    return json({ isAuthenticated: context.accessToken })
}

export default function Index() {
    const loaderData = useLoaderData<typeof loader>()

    return (
        <>
            <div
                className={
                    'w-full flex flex-col gap-3 justify-center items-center'
                }
            >
                <span className="text-center text-3xl max-w-prose">
                    Учишься в 10-м или 11-м классе и скоро сдаёшь ЕГЭ по
                    русскому языку?
                </span>
                <span className="text-center text-2xl font-bold">
                    Значит, ты на верном пути!
                </span>

                <img
                    src={'/biglogo-landing.png'}
                    height={500}
                    width={300}
                    alt={'Логотип Examo'}
                />

                <Button asChild>
                    {loaderData.isAuthenticated ? (
                        <Link to={'/dashboard'}>Личный кабинет</Link>
                    ) : (
                        <Link to={'/auth/sign-up'}>Регистрация в Экзамо</Link>
                    )}
                </Button>

                <div className={'grid grid-cols-1 lg:grid-cols-3'}>
                    <div className={'flex flex-col items-center p-3 lg:p-8'}>
                        <div className="text-center text-3xl font-bold">
                            Зачем всё это надо?
                        </div>
                        <img
                            src={'/catlanding1.png'}
                            height={500}
                            width={200}
                            alt={'Задумчивая кошка'}
                        />
                        <div className={'text-justify max-w-prose'}>
                            Ты сможешь выполнять тесты для ЕГЭ по русскому языку
                            на все нужные и ненужные темы, сможешь выбирать свои
                            &quot;любимые&quot; темы, чтобы их проработать.
                            Сможешь тестироваться и на компьютере, и на
                            планшете, и на мобильном телефоне.
                        </div>
                    </div>

                    <div className={'flex flex-col items-center p-3 lg:p-8'}>
                        <div className="text-center text-3xl font-bold">
                            Почему именно Экзамо?
                        </div>
                        <img
                            src={'/catlanding2.png'}
                            height={500}
                            width={200}
                            alt={'Ошарашенная кошка'}
                        />
                        <div className={'text-justify max-w-prose'}>
                            У нас есть кошка, которая хочет кушать, играть,
                            делать уборку и учиться, — и всё это с помощью
                            тестов для ЕГЭ по русскому языку. Заведи кошку — и
                            ты захочешь её постоянно кормить, захочешь делать с
                            ней уборку — с помощью разных тестов! Ты сможешь
                            постоянно заходить в Экзамо и выполнять маленькие
                            тестики для ЕГЭ по русскому — на 5-10 минут, будешь
                            каждый день понемногу тренироваться.
                        </div>
                    </div>

                    <div className={'flex flex-col items-center p-3 lg:p-8'}>
                        <div className="text-justify text-xl font-bold">
                            &quot;А как же всё это попробовать?! Я хочу
                            немедленно всё это попробовать!!1&quot;
                        </div>
                        <img
                            src={'/catlanding3.png'}
                            height={500}
                            width={200}
                            alt={'Нетерпеливая кошка'}
                        />
                        <div className={'text-justify max-w-prose mb-10'}>
                            Просто зарегистрируйся и начни кормить кошку, делать
                            с ней уборку, учиться. Это же бесплатно! Ну или
                            попробуй первый тест без регистрации:
                        </div>
                        <Button asChild>
                            <Link to={'/quizzes?free=true'}>
                                Начать тест{' '}
                                {!loaderData.isAuthenticated && ' без регистрации'}
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
